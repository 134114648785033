<template>
  <div class="py-4">
    <div class="mb-2 d-flex d-md-none d-lg-none align-center justify-end">
      <v-tooltip v-auth-acl="vAuthAcl + '-import'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" fab dark small class="mr-2" @click="openDialogImportar" v-bind="attrs" v-on="on">
            <v-icon dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </template>
        <span>Importar Información</span>
      </v-tooltip>
      <v-tooltip v-auth-acl="vAuthAcl + '-export'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
            <v-icon dark>mdi-cloud-download</v-icon>
          </v-btn>
        </template>
        <span>Exportar BD</span>
      </v-tooltip>
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="listado"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
      :height="height"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">CUENTAS CONTABLES</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 pt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="d-none d-md-flex d-lg-flex mx-4" vertical></v-divider>
          <div class="w-30 d-none d-md-flex d-lg-flex justify-space-between align-center">
            <v-tooltip v-auth-acl="vAuthAcl + '-export'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark small class="mr-2" @click="exportarbase" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-cloud-download</v-icon>
                </v-btn>
              </template>
              <span>Exportar BD</span>
            </v-tooltip>
            <v-tooltip v-auth-acl="vAuthAcl + '-import'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark small @click="openDialogImportar" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </template>
              <span>Importar Información</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        <v-icon :color="item.activo == 1 ? 'green' : 'red darken-2'" small>
          {{ item.activo == 1 ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
    </v-data-table>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <Importar
      v-auth-acl="vAuthAcl + '-import'"
      ref="importar"
      :dialog="dialogImportar"
      @close="closeImportar"
      @importar="importar"
    ></Importar>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';
export default {
  name: 'MatrizLista',
  components: {
    Importar: () => import('../components/Importar')
  },
  data: () => ({
    height: 130,
    search: '',
    dialogImportar: false,
    showLoading: false,
    matrizService: null,
    headers: [
      {
        text: 'N°',
        value: 'numero',
        width: '100',
        class: 'header-items-fixed-ind nth-child1 primary',
        fixed: true
      },
      {
        text: 'CÓDIGO CUENTA',
        value: 'idcuentacontable',
        width: '160',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'DESCRIPCIÓN', value: 'descripcion', width: '300', class: 'primary' },
      { text: 'ACTIVO', value: 'activo', width: '100', class: 'primary' }
    ],
    listado: [],
    vAuthAcl: 'gh-pres-cuenta-contable'
  }),
  computed: {},
  watch: {},
  methods: {
    async openDialogImportar() {
      this.dialogImportar = true;
    },
    async exportarbase() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de exportar la información?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const fecha1 = moment().format('YYYYMMDDHHMMSS');
        const nombreentregable = 'EXPORT-CUENTAS-CONTABLES-' + fecha1 + '.xlsx';
        const token = localStorage.getItem('token');
        await this.matrizService.downloadResource(
          'exportarCuentasContables',
          {
            token: token
          },
          nombreentregable
        );
        this.showLoading = false;
      }
    },
    async initialize() {
      this.showLoading = true;
      this.listado = [];

      const res = await this.matrizService.get().execResource('listarCuentasContables');
      this.listado = res.map((element, index) => {
        return { numero: index + 1, class: 'class-items-fixed-ind', ...element };
      });
      this.height = 400;
      this.showLoading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    closeImportar() {
      this.dialogImportar = false;
    },
    async importar(listado) {
      this.showLoading = true;
      const res = await this.matrizService.post().execResource('importarCuentasContables', {
        idempresa: decryptAES(localStorage.getItem('emp')),
        cuentacontable: listado
      });
      this.alertDialog(res.status ? 'success' : 'error', res.data);
      if (res.status) {
        this.initialize();
        this.$refs.importar.close();
      }
      this.showLoading = false;
    }
  },
  async mounted() {},
  async created() {
    this.matrizService = this.$httpService(new MatrizService(), this);
    //await this.permisos();
    await this.initialize();
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
