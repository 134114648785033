class MatrizService {
  constructor() {}
  resources = () => ({
    listarCuentasContables: {
      uri: `/v1/presupuesto/listar-cuenta-contable`,
      method: ['get']
    },
    exportarCuentasContables: {
      uri: `/v1/exportarcuentacontable`,
      method: ['get']
    },
    exportarModeloCuentasContables: {
      uri: `/v1/exportarformatocuentacontable`,
      method: ['get']
    },
    importarCuentasContables: {
      uri: `/v1/presupuesto/importar-cuenta-contable`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    }
  });
}

export default MatrizService;
